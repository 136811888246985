













































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import { Location } from 'vue-router';
import { useDeviceStore } from '@/devicehub/apps/dhStudy/stores/deviceStore';
import { DeviceDetail } from '@/generated/api/study';
import { useRouter } from '@/devicehub/utils/router';
import { useCopyId } from '@/devicehub/utils/actions';
import { useI18n } from '@/devicehub/utils/i18n';
import PerPage from '@/devicehub/components/PerPage.vue';
import BatteryDisplay from '@/apps/monitoring/components/BatteryDisplay.vue';
import { dataRouteName } from '@/apps/data/app';

export default defineComponent({
  components: { PerPage, BatteryDisplay },
  setup() {
    const deviceStore = useDeviceStore();
    const router = useRouter();
    const i18n = useI18n();
    const { onCopyId } = useCopyId((d: DeviceDetail) => d.device.id);
    const pageSize = ref(10);

    onMounted(async () => {
      await deviceStore.getSourceDevices();
    });

    const detailRoute = (deviceDetail: DeviceDetail): Location => {
      return {
        name: dataRouteName('device-relation-detail-device'),
        params: {
          id: deviceDetail.device.id,
          app_handle: deviceStore.dataClientAppHandle,
        },
      };
    };

    const onRowClick = (deviceDetail: DeviceDetail): void => {
      router.push(detailRoute(deviceDetail));
    };

    const getDashboardUrl = (deviceDetail: DeviceDetail): string => {
      return `${deviceStore.deviceDashboardUrl}${deviceDetail.device.id}`;
    };

    const footer = computed((): string => {
      const total = deviceStore.filteredSourceDevices.length;
      return `${total} ${i18n.tc('dhStudy.device.this', total)}`;
    });

    return {
      deviceStore,
      detailRoute,
      getDashboardUrl,
      pageSize,
      onRowClick,
      onCopyId,
      footer,
    };
  },
});
